import { useMemo } from "react";
import { useReviewIndexRoute } from "@tecnobit-srl/route-types-review-index/lazy";
const useReviewsRouteTypes = () => {
  const reviewIndexRoute = useReviewIndexRoute();
  return useMemo(
    () => ({
      // ...yourPackage,
      ...reviewIndexRoute
    }),
    [
      // yourPackage,
      reviewIndexRoute
    ]
  );
};
export {
  useReviewsRouteTypes
};
